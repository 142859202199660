.button {
  @apply py-4 px-4 flex items-center justify-center font-black ring-transparent ring-inset ring-4;
  min-width: 12rem;
}

.button:disabled {
  @apply bg-gray-200 text-gray-400 ring-gray-200 cursor-default;
}

.wide {
  @apply w-full;
}

.primary {
  @apply bg-gold-400 ring-gold-400;
}

.secondary {
  @apply bg-blue-500 ring-blue-500 text-white active:text-black;
}

.brand {
  @apply bg-brand ring-brand text-white active:text-black;
}

.tertiary {
  @apply bg-accentBlue ring-accentBlue text-white active:text-black;
}
